//import { HomeTutorPage } from './home-tutor/home-tutor.page';
import { Component } from '@angular/core';

import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AndroidFullScreen } from '@ionic-native/android-full-screen/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
//import { Deeplinks } from '@ionic-native/deeplinks/ngx';
//import { HomePage } from './home/home.page';

import { environment } from '../environments/environment';
import { DataService } from './services/data.service';

import { TranslateService } from '@ngx-translate/core';

//import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Home Tutor',
      url: '/home-tutor',
      icon: 'home'
    },
    {
      title: 'List',
      url: '/list',
      icon: 'list'
    }
  ];

  public menuVisible = false;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private androidFullScreen: AndroidFullScreen,
    private screenOrientation: ScreenOrientation,
    public translate: TranslateService,
    public dataSrv: DataService/*,
    private androidPermissions: AndroidPermissions*/
    /*,
    private deeplinks: Deeplinks*/
  ) {
    this.initializeApp();
  }

  async init() {
    /* let bridgeUrl = await this.dataSrv.getItem("bridgeUrl");
    if (bridgeUrl) {
      environment.bridgeUrl = await this.dataSrv.getItem("bridgeUrl");
      environment.mediaUrl = await this.dataSrv.getItem("mediaUrl");
      console.log(environment.bridgeUrl);
      console.log(environment.mediaUrl);
    } */
  }

  initializeApp() {
    this.platform.ready().then(() => {

      // this.init();

      this.statusBar.styleDefault();
      this.statusBar.hide();
      this.splashScreen.hide();
      this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE);
      if (this.platform.is('android')) {
        this.androidFullScreen.isImmersiveModeSupported()
          .then(() => {
            console.log('Immersive mode supported');
            this.androidFullScreen.immersiveMode();
          })
          .catch(err => console.log(err));
      }
    });

    this.translate.addLangs(['de']);
    this.translate.setDefaultLang('de');
    this.translate.use('de');

    /*
    let plist: any[] = [];

    this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA).then(
      result => {
        console.log('Has permission CAMERA?', result.hasPermission);
        if ( !result.hasPermission )
          plist.push(this.androidPermissions.PERMISSION.CAMERA);
      },
      err => {
        plist.push(this.androidPermissions.PERMISSION.CAMERA);
        //this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA);
      }
    );

    this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.RECORD_AUDIO).then(
      result => {
        console.log('Has permission RECORD_AUDIO?', result.hasPermission);
        if ( !result.hasPermission )
          plist.push(this.androidPermissions.PERMISSION.RECORD_AUDIO);
      },
      err => {
        plist.push(this.androidPermissions.PERMISSION.RECORD_AUDIO);
        //this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.RECORD_AUDIO);
      }
    );

    //this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.RECORD_VIDEO).then(
    //  result => {
    //    console.log('Has permission RECORD_VIDEO?', result.hasPermission);
    //    if ( !result.hasPermission )
    //      plist.push(this.androidPermissions.PERMISSION.RECORD_VIDEO);
    //  },
    //  err => {
    //    plist.push(this.androidPermissions.PERMISSION.RECORD_VIDEO);
    //    //this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.RECORD_VIDEO);
    // }
    //);

    this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE).then(
      result => {
        console.log('Has permission READ_EXTERNAL_STORAGE?', result.hasPermission);
        if ( !result.hasPermission )
          plist.push(this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE);
      },
      err => {
        plist.push(this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE);
        //this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE);
      }
    );

    this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(
      result => {
        console.log('Has permission WRITE_EXTERNAL_STORAGE?', result.hasPermission);
        if ( !result.hasPermission )
          plist.push(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE);
      },
      err => {
        plist.push(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE);
        //this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE);
      }
    );
    
    this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.MODIFY_AUDIO_SETTINGS).then(
      result => {
        console.log('Has permission MODIFY_AUDIO_SETTINGS?', result.hasPermission);
        if ( !result.hasPermission )
          plist.push(this.androidPermissions.PERMISSION.MODIFY_AUDIO_SETTINGS);
      },
      err => {
        plist.push(this.androidPermissions.PERMISSION.MODIFY_AUDIO_SETTINGS);
        //this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.MODIFY_AUDIO_SETTINGS);
      }
    );

    this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.READ_PHONE_STATE).then(
      result => {
        console.log('Has permission READ_PHONE_STATE?', result.hasPermission);
        if ( !result.hasPermission )
          plist.push(this.androidPermissions.PERMISSION.READ_PHONE_STATE);
      },
      err => {
        plist.push(this.androidPermissions.PERMISSION.READ_PHONE_STATE);
        //this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.READ_PHONE_STATE);
      }
    );


    this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CALL_PHONE).then(
      result => {
        console.log('Has permission CALL_PHONE?', result.hasPermission);
        if ( !result.hasPermission )
          plist.push(this.androidPermissions.PERMISSION.CALL_PHONE);
      },
      err => {
        plist.push(this.androidPermissions.PERMISSION.CALL_PHONE);
        //this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CALL_PHONE);
      }
    );
    */

    //this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.CAMERA, this.androidPermissions.PERMISSION.RECORD_AUDIO, this.androidPermissions.PERMISSION.RECORD_VIDEO, this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE, this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE]);

    //this.androidPermissions.requestPermissions(plist);

    // HIER: Tablet Device Language auslesen und ggf setzen - falls notwendig.
    //const browserLang = translate.getBrowserLang();
    //translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');

    /*
    this.deeplinks.route({
      '/home-tutor': HomeTutorPage,
      '/home': HomePage
    }).subscribe(match => {
      // match.$route - the route we matched, which is the matched entry from the arguments to route()
      // match.$args - the args passed in the link
      // match.$link - the full link data
      console.log('Successfully matched route', match);
    }, nomatch => {
      // nomatch.$link - the full link data
      console.error('Got a deeplink that didn\'t match', nomatch);
    });
    */
  }

}
